<template>
  <div class="exam">
    <TopBar :pageTitle="'考试记录'"/>
    <div class="record">
      <p>考试成绩：<span class="num">{{examInfo.score}}</span></p>
      <p>开始时间：{{examInfo.created_at}}</p>
      <p>结束时间：{{examInfo.updated_at}}</p>
    </div>
    <ul class="exam-list no-exam" >
      <li class="exam-info">
        <!-- <p class="type-name">{{item.type_name}}<span class="sub-title">(共{{item.question_list.length}}题，合计{{item.total_score}}分)</span></p> -->
        <ul class="question-list" v-for="(question, index) in examInfo.record" :key="question.id">
          <li class="question-title"><span class="qid">{{index+1}}、</span>{{question.question}}</li>
          <li class="option" v-for="option in question.selectdata" :key="option.key">
            <label class="option-label">
              <div class="checkbox type2" :class="{'active': question.user_answer && question.user_answer.includes(option.key)}"></div>
              <span>{{option.key}} {{option.value}}</span>
            </label>
          </li>
          <li class="sure true" v-if="question.is_right==1"> 答题结果：<span>对</span></li>
          <li class="sure false" v-if="question.is_right==0"> 答题结果：<span>错</span> </li>
          <li class="sure" v-if="question.is_right==0"> 正确答案：{{ question.answer }}</li>
        </ul>
      </li>
    </ul>
    <div class="sheet-modal" v-if="sheetModal">
      <div class="content">
        <p class="content-title">答题卡<span class="close" @click="sheetModal=false"></span></p>
        <ul class="answer-sheet" v-for="item in examInfo.questions" :key="item.type_name">
          <li class="category">
            <p class="category-title">{{item.type_name}}</p>
            <span class="test-num-box" :class="selectedDta[`question_${item.type}_${num.id}`] !=''?'active':''" v-for="(num, index) in item.question_list" :key="num.id">{{index + 1}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue'
import {mapMutations} from "vuex";
export default {
  name: 'm-exam',
  data(){
    return {
      limitTime: '',     //倒计时时间
      sheetModal: false, //答题卡弹窗
      pageTitle: '',     //页面名
      examId: '',        //试题id
      pageName: '',      //页面name
      examInfo: '',      //试题详情
      selectedDta: '',   //选中数据
      over: false,       //是否结束
      timer: '',         //计时器
      typeIndex:'',
    }
  },
  components: {
    TopBar
  },
  mounted(){
    this.examId = this.$route.query.id;  //班级id
    this.getExamInfo();                   //获取试题内容
  },
  methods: {
    ...mapMutations(['ShowTipModal', 'CloseTipModal']), //tip弹窗
    //获取试题内容
    getExamInfo(){
        this.$axios.post(`/v1/commonExam/examRecord`, {record_id: this.examId}, {useLog: true}).then(res => {
          if(res.code == 0){
            this.examInfo = res.data;
          }else{
            this.ShowTipModal({
              text: res.msg,
              fun: ()=>{
                this.$router.back(-1);
              }
            })
          }
        })
      
    },
    //倒计时
    countdown(date){
      let _this = this;
      let maxtime = date * 60; //一个小时，按秒计算，自己调整!

      this.timer = setInterval(function(){
        if (maxtime >= 0) {
          let minutes = Math.floor(maxtime / 60);
          let seconds = Math.floor(maxtime % 60);
          let msg = minutes + "分" + (seconds !=0 ? seconds + "秒" : '');
          _this.limitTime = msg;
          // if (maxtime == 5 * 60)alert("还剩5分钟");
          --maxtime;
        } else{
          clearInterval(this.timer);
          _this.ShowTipModal({
            text: '答题时间已到，停止答题',
            fun: function(){
              _this.CloseTipModal();
              _this.subBtn();
            }
          })
        }
      }, 1000)
    },
  },
  // beforeRouteLeave(to, from, next){
  //   if(this.$route.name=="ExamRecord"){
  //     clearInterval(this.timer);
  //     next();
  //   }else{
  //     let toUrl = '/examInfo/'+this.examId;
  //     if(this.over){
  //       clearInterval(this.timer);
  //       if(to.path == toUrl){
  //         next();
  //       }else{
  //         next(toUrl);
  //       }
  //     }else{
  //       this.ShowTipModal({
  //         text: '您还未完成考试，确定结束本次考试？',
  //         showBtns: true,
  //         confirmFun: ()=>{
  //           clearInterval(this.timer);
  //           this.CloseTipModal();
  //           this.over = true;
  //           next(toUrl);
  //         }
  //       })
  //     }
  //   }
  // }
}
</script>
<style scoped lang="scss">
.tip-bg{
  background: #fff;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 50px;
  z-index: 888;
  .tip{
    box-sizing: border-box;
    width: 100%;
    padding: 10px 18px;
    background: rgba(245, 166, 35, 0.14);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .countdown{
      font-size: 14px;
      color: #999;
    }
    .date{
      font-size: 14px;
      color: #F5A623;
      font-weight: bold;
    }
    .score{
      font-size: 14px;
      color: #666;
    }
  }
}
.exam{
  padding-bottom: 30px;
}
.exam-list{
  padding: 20px 18px;
}
.type-name{
  padding-bottom: 20px;
  border-bottom: 1px solid #E5E5E5;
  font-size: 15px;
  font-weight: 500;
  .sub-title{
    font-size: 12px;
    font-weight: 400;
    color: #999;
    margin-left: 9px;
  }
}
.question-list{
  margin-bottom: 20px;
  .sure{
    padding: 5px 0;
    &.true{
      color: green;
    }
    &.false{
      color: red;
    }
  }
}
.question-title{
  padding: 20px 0 14px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
  .qid{
    display: block;
    font-weight: normal;
  }
}
.no-exam{
  .type2{
    &.active{
      background-color: #999;
    }
  }
}
.option-label{
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 10px;
  .checkbox{
    width: 15px;
    height: 15px;
    border-width: 1px;
    margin-right: 16px;
    &.active{
      background-size: 9px;
    }
  }
}
.sheet-modal{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  .content{
    width: 100%;
    height: 400px;
    padding: 15px 18px;
    background: #FFF;
    padding-bottom: 30px;
    overflow-y: auto;
    position: absolute;
    left: 0;
    bottom: 0;
    .content-title{
      font-size: 15px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      top: 0;
    }
    .close{
      display: block;
      width: 50px;
      height: 50px;
      background: url(~@/assets/m/wrong-black.png) no-repeat center center;
      background-size: 23px;
    }
    .answer-sheet{
      padding-top: 30px;
    }
    .category-title{
      font-size: 14px;
      padding-bottom: 8px;
      overflow: hidden;
    }
    .test-num-box{
      display: inline-block;
      width: 38px;
      height: 38px;
      border: 1px solid #E5E5E5;
      border-radius: 2px;
      font-size: 14px;
      line-height: 38px;
      text-align: center;
      margin: 0 2px 2px 0;
      &.active{
        background-color: #0065DF;
        color: #FFF;
      }
    }
  }
}
.sub-btn{
  width: 340px;
}
.record{
  padding: 18px;
  background-color: #f6f6f6;
  line-height: 24px;
  p:first-child{
    margin-bottom: 5px;
  }
  .num{
    font-size: 20px;
    font-weight: bold;
    color: red;
  }
}
</style>